<template>
  <div id="app">
    <header>
      <Nav />
    </header>
    <Hero id="HeroId" />
    <MusicRight id="MusicId"
    date="October 29, 2020" 
    title="Here I'm standing on the heath, the whole bunch of possibilities." 
    content="Inspired by Paulo Coelho's masterpiece &quot;Alchemist&quot; and the world's driest Chilean &quot;Atacama Desert,&quot; [Blooming] is a song full of love for humans. A bright dawn can come only when there is a dark night, and seeds alive and wait for bloom even under the driest desert land." />
    <MusicLeft
    date="July 1, 2020" 
    title="I wish anyone could hear this song and dance anywhere." 
    content="Could something magical happen in an old cramped supersingle bed? Heather, who released the mini-album ‘On a Day Like Today’ with the acoustic duo ‘Sesame&Salt’ last year, returned. 'Super Single Magician', a familiar yet new musical style, is a song containing the ambitions and dreams of ordinary people who can be seen anywhere." />
    <VideoPlayer id="VideoPlayerId" />
    <Contact id="ContactId" />
    <Footer />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Nav from "@/components/Nav.vue";
import Hero from "@/components/Herosection.vue";
import MusicRight from "@/components/MusicRight.vue";
import MusicLeft from "@/components/MusicLeft.vue";
import VideoPlayer from "@/components/VideoPlayer";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";

import "./App.scss";

export default defineComponent({
  name: 'App',
  components: {
    // HelloWorld,
    Nav,
    Hero,
    MusicRight,
    MusicLeft,
    VideoPlayer,
    Contact,
    Footer,
  }
});
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}
  #app {
    width: 100%; //why?
    #VideoPlayerId {
      background: linear-gradient(0.25turn, #FDFFFD, #E2E4EC);
    }
  }
</style>
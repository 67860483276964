<template>
<section class="music-wrap">
  <div class="music-image">
    <img src="@/assets/album_ssm.png">
    <!-- <img src="@/assets/d.png"> -->

  </div>
  <div class="music-explain">
    <div class="music-date">
      <a>{{date}}</a>
    </div>
    <div class="music-title">
      <a>{{title}}</a>
    </div>
    <div class="music-content">
      <a>{{content}}</a>
    </div>
    <div class="music-link">
      <a>Links</a>
    </div>
  </div>
</section>   
</template>

<script>
export default {
    name: 'MusicLeft',
      props: {
      date: String,
      title: String,
      content: String,
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/custom.scss';

.music-wrap {
  @include width_height(var(--u-preview-width), var(--u-musicsection-height));
  display: flex;
  margin: 0 0 2vw 0;
  .music-image {
    display: flex;
    align-items: center;
    img {
      width: var(--u-musicsection-image-height);
    }
  }
  .music-explain {
    margin: 2vw 0 3vw 4vw;
    display: flex;
    flex-direction: column;
    .music-date {
      flex-grow: 1;
      font-family: Gill Sans;
      font-weight: 700;
      color: #A7AAB0;
      font-size: var(--u-musicsection-date-text-size);
    }
    .music-title {
      flex-grow: 1;
      font-family: Gill Sans;
      font-weight: bold;
      letter-spacing: -0.02em;
      color: #2C323D;
      font-size: var(--u-musicsection-title-text-size);
    }
    .music-content {
      flex-grow: 4;
      margin: 0 1vw 0 0;
      font-family: Gill Sans;
      font-style: normal;
      font-weight: normal;
      font-size: var(--u-musicsection-content-text-size);
      line-height: 162.3%;
      letter-spacing: -0.01em;
      color: #707070;
    }
    .music-link {
      border-top: 1px solid black; 
      border-bottom: 1px solid black;
      height: var(--u-musicsection-link-height);

      display: flex;
      align-items: center;

      margin: 0 2vw 0 0;
      font-family: Gill Sans;
      font-weight: 400;
      color: #000000;
      font-size: var(--u-musicsection-date-text-size);
    }
  }
}
</style>
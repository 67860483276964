<template>
  <div class="image_stack">
    <div class="heroimage">
      <img src="@/assets/heroimage.jpg">
      <!-- <img src="@/assets/d.png"> -->

    </div>
    <div class="h3athrsun_logo">
      <img src="@/assets/h3athrsun_logo.png">
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Hero',
})
</script>

<style lang="scss" scoped>
.image_stack{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // background-size: cover;

  .heroimage {
    display: contents;
    img {
     width: 100%;
    }
  }
  .h3athrsun_logo {
    img {
      width: 10vw;
      margin: -5vw 0px 5vw 0px;
    }
  }
}
</style>
<template>
  <footer class="w-footer">
    <div class="rights">
      &#169; 2021 All Rights Reserved. 
    </div>
    <div class="author">
      Design by Dasoll Park.
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss" scoped>
@import '../../scss/custom.scss';

.w-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: 10px 30px;
  background-color: var(--color-grey-500);
  display: flex;
  align-items: center;
  justify-content: center;
  .rights {
    font-family: Gill Sans;
    font-size: 16px;
    opacity: 0.9;
    margin: 18px 0;
    line-height: 1.7;
  }
  .author {
    font-family: Gill Sans;
    font-size: 16px;
    opacity: 0.9;
    margin: 18px 0;
    line-height: 1.7;
  }
}
</style>
<template>
  <section class="videoplayersection">
    <div id="container">
      <!-- <div class="player" v-for="item in videos" :key="item.videoId"> -->
      <Player 
      class="player" v-for="item in videos" :key="item.videoId"
        playsinline 
        ref="player"
        @vPlaybackReady="onPlaybackReady"
      >
        <Video :poster="item.poster">
          <source :data-src = item.videoId type="video/mp4">
        </Video>
        <DefaultUi>
          <TapSidesToSeek />
        </DefaultUi>
      </Player>
    </div>
  </section>
</template>

<script>
import { reactive, defineComponent } from 'vue';
import { Player, Video, DefaultUi } from '@vime/vue-next';
import '@vime/core/themes/default.css';
import TapSidesToSeek from './TapSidesToSeek';

const useValues = () => {
  const videoProperty = reactive([
    {videoId: "https://jaylee-test.s3.ap-northeast-2.amazonaws.com/test1_1080p.mp4", videoType: "video/mp4", poster: "https://jaylee-test.s3.ap-northeast-2.amazonaws.com/test1_poster.png"},
    {videoId: "https://jaylee-test.s3.ap-northeast-2.amazonaws.com/test2_1080p.mp4", videoType: "video/mp4", poster: "https://jaylee-test.s3.ap-northeast-2.amazonaws.com/test2_poster.png"},
  ])
  return { videoProperty };
}

export default defineComponent({
  name: 'VideoPlayer',
  components: {
    Player,
    Video,
    DefaultUi,
    TapSidesToSeek,
  },
  get player() {
    return this.$refs.player;
  },

  setup() {
    const { videoProperty } = useValues();
    return {
      videos: videoProperty,
    }
  }
});
</script>

<style lang="scss">
@import '../../../scss/custom.scss';

.videoplayersection {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0.25turn, #FDFFFD, #E2E4EC);
  // margin-bottom: calc(0px - var(--u-preview-width) * 0.45 * 0.2 * 2); // for linear grad. background and fit with footer
}
#container {
  // max-width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  margin-top: calc(var(--u-preview-width) * 0.45 * 0.2);
  margin-bottom: calc(var(--u-preview-width) * 0.45 * 0.2);

  .player {
    width: var(--u-preview-width);
    margin-bottom: calc(var(--u-preview-width) * 0.45 * 0.2);
  }
}

@media screen and (min-width: 1200px){
  #container {
    width: var(--u-preview-width);
    margin-bottom: calc(var(--u-preview-width) * 0.45 * 0.2 * 3); // for linear grad. background + player margin

    .player {
      width: calc(var(--u-preview-width) * 0.45);
      max-width: 600px;
      margin-bottom: calc(0px - var(--u-preview-width) * 0.45 * 0.2);
    }
  }
  #container > vm-player:nth-child(even) {
    align-self: flex-end;
  }
}

@media screen and (min-width: 1920px){
  #container {
    max-width: var(--u-preview-width); 
  }
}
@media (max-width: 767.98px) { // since preview-width is % in this area
  #container {
    width: 90%;
    align-items: center;

    .player {
      width: 100%;
      margin-bottom: calc(var(--u-preview-width) * 0.45 * 0.2);
    }
  }
}
</style>

<template>
  <section class="contact-section">
    <div class="content-title">
      H3ATHR.SUN@GMAIL.COM
    </div>
    <div class="content-image">
      <div class="image-wrap">
        <a href="https://www.youtube.com/channel/UCks_LlrwW9zucWJPBPfuIvA">
          <img src="@/assets/logo/logo_youtube.svg" />
        </a>
      </div>

      <div class="empty" />

      <div class="image-wrap">
        <a href="https://www.instagram.com/what_the_heng/">
        <img src="@/assets/logo/logo_instagram.svg" />
        </a>
      </div>

      <div class="empty" />

      <div class="image-wrap">
        <a href="https://www.facebook.com/H3ATHRSUN/">
          <img src="@/assets/logo/logo_facebook.svg" />
        </a>
      </div>

      <div class="empty" />

      <div class="image-wrap">
        <a href="mailto:h3athr.sun@gmail.com">
          <img src="@/assets/logo/logo_email.svg" />
        </a>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Contact',
})
</script>

<style lang="scss" scoped>
@import '../../scss/custom.scss';

  .contact-section {
    display: flex;
    flex-direction: column;
    margin-top: calc(var(--u-preview-width) * 0.45 * 0.2);
    margin-bottom: calc(var(--u-preview-width) * 0.45 * 0.2);

    .content-title {
      align-self: center;
      font-family: Gill Sans;
      font-weight: bold;
      letter-spacing: -0.02em;
      color: #AECBFB;
      font-size: calc(var(--u-musicsection-title-text-size) * 1.5);
    }
    .content-image {
      text-align: center;
      margin-top: calc(var(--u-preview-width) * 0.45 * 0.2);
      margin-left: 10%;
      margin-right: 10%;
      display: flex;
      align-self: center;
      }
    .empty {
      display: flex;
      flex-grow: 1;
    }
    .image-wrap {
      display: flex;
      flex-grow: 1;
      align-items: center;
      img {
        width: 50%;
        max-width: 200px;
      }
    }
  }

@media screen and (max-width: 400px) {
  .contact-section {
    .content-title {
      font-size: calc(var(--u-musicsection-title-text-size));
    }
  }
}
</style>
<template>
  <nav>
    <div class="nav-cont">
      <div class="w-logo">
        <router-link :to="'/'">
          <img src="@/assets/logo/logo_h3athrsun.png" />
        </router-link>
      </div>
      <div class="nav-menu">
        <a class="dropdown" href="#HeroId">HOME</a>
        <a class="dropdown" href="#MusicId">MUSIC</a>
        <a class="dropdown" href="#VideoPlayerId">VIDEOS</a>
        <a class="dropdown" href="#ContactId">CONTACT</a>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'Nav',
}
</script>

<style lang="scss" scoped>
@import '../../scss/custom.scss';

nav {
  @include width_height(100%, var(--u-nav-height));
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: transparent;

  .nav-cont {
    @extend .flex-row;
    justify-content: space-between;
    position: relative;
    @include width_height(var(--u-preview-width), var(--u-nav-height));
    // max-width: 1440px;
    line-height: var(--u-nav-height);
    // padding: 0 var(--u-nav-padding);
    font-family: Gill Sans;
    color: #000000;
    font-size: var(--u-nav-font-size);
    font-weight: bold;
    line-height: 21px;
    text-decoration-line: underline;
    margin: 0 auto;
    
    .w-logo {
      display: flex;
      width: var(--u-nav-logo-w);
      margin-left: var(--u-nav-logo-ml);
      
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .nav-menu {
    width: auto;
    height: 100%;
    @extend .flex-row;
    justify-content: flex-end;
    .dropdown {
      position: relative;
      text-align: center;
      transition: 0s 0.1s;
      @include width_height('auto', var(--u-nav-height));
      height: auto;
      // margin: 0 20px;
      margin-right: 30px;
      color: #000000;
      @media (max-width: 575.98px) {
        margin-right: 15px;
      }
    }
    @media (max-width: 578px) {
      min-width: 50%;
    }
  }
}

:-webkit-full-screen {
  nav {
    display: none;
  }
}

/* Firefox syntax */
:-moz-full-screen {
  nav {
    display: none;
  }
}

/* IE/Edge syntax */
:-ms-fullscreen {
  nav {
    display: none;
  }
}

/* Standard syntax */
:fullscreen {
  nav {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .w-dropdown {
    .username {
      display: none;
    }
  }
}
</style>
<template>
  <section class="music-wrap">
    <div class="music-explain">
      <div class="music-date">
        <!-- <a>October 29, 2020</a> -->
        <a>{{date}}</a>
      </div>
      <div class="music-title">
        <!-- <a>Here I'm standing on the heath, the whole bunch of possibilities.</a> -->
        <a>{{title}}</a>
      </div>
      <div class="music-content">
        <!-- <a>Inspired by Paulo Coelho's masterpiece "Alchemist" and the world's driest Chilean "Atacama Desert," [Blooming] is a song full of love for humans.
  A bright dawn can come only when there is a dark night, and seeds alive and wait for bloom even under the driest desert land.</a> -->
        <a>{{content}}</a>
      </div>
      <div class="music-link">
        <a>Links</a>
      </div>
    </div>
    <div class="music-image">
      <img src="@/assets/album_blooming.png">
      <!-- <img src="@/assets/d.png"> -->
    </div>
  </section>   
</template>

<script>
export default {
  name: 'MusicRight',
  props: {
    date: String,
    title: String,
    content: String,
  },
}  
</script>

<style lang="scss" scoped>
@import '../../scss/custom.scss';

.music-wrap {
  @include width_height(var(--u-preview-width), var(--u-musicsection-height));
  display: flex;
  margin: 0 0 2vw 0;
  padding-top: var(--u-nav-height); // for router navigation
  .music-explain {
    margin: 2vw 2vw 3vw 0;
    display: flex;
    flex-direction: column;
    .music-date {
      flex-grow: 1;
      font-family: Gill Sans;
      font-weight: 700;
      color: #A7AAB0;
      font-size: var(--u-musicsection-date-text-size);
    }
    .music-title {
      flex-grow: 1;
      font-family: Gill Sans;
      font-weight: bold;
      letter-spacing: -0.02em;
      color: #2C323D;
      font-size: var(--u-musicsection-title-text-size);
    }
    .music-content {
      flex-grow: 4;
      margin: 0 2vw 0 0;
      font-family: Gill Sans;
      font-style: normal;
      font-weight: normal;
      font-size: var(--u-musicsection-content-text-size);
      line-height: 162.3%;
      letter-spacing: -0.01em;
      color: #707070;
    }
    .music-link {
      border-top: 1px solid black; 
      border-bottom: 1px solid black;
      height: var(--u-musicsection-link-height);

      display: flex;
      align-items: center;

      margin: 0 2vw 0 0;
      font-family: Gill Sans;
      font-weight: 400;
      color: #000000;
      font-size: var(--u-musicsection-date-text-size);
    }
  }
  .music-image {
    display: flex;
    align-items: center;
    img {
      width: var(--u-musicsection-image-height);
    }
  }
}
</style>